<template>
  <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
    <template v-slot:activator="{ on, attrs }">
        <v-btn 
            color="secondary"
            dark 
            v-bind="attrs"
            v-on="on"
        >
            <v-icon class="pr-2">mdi-magnify</v-icon>
            <slot name="texto-busqueda">buscar</slot>
        </v-btn>
    </template>
    <v-card >
      <v-toolbar color="secondary">
        <span class="subheading white--text">
          <v-icon color="white">mdi-magnify</v-icon>
          {{titulo}}
        </span>
      </v-toolbar>
      <v-progress-linear :active="enviado" class="ma-0" color="accent" height="5" indeterminate></v-progress-linear>
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12" md="3" xs="6" class="px-1">
            <v-text-field
              label="Numero Documento"
              class="required"
              @keydown.enter="filtrar()"
              :disabled="enviado"
              hint="No es necesario Expedición"
              persistent-hint
              v-model="abogado.numeroDocumento"
              filled
            ></v-text-field>
          </v-col>
          <!-- <v-col lg4 md2 xs5>
            <v-text-field
              label="Complemento"
              class="required px-2"
              :disabled="enviado"
              v-model="abogado.complemento"
            ></v-text-field>
          </v-col> -->
          <!-- <v-flex lg4 md4 xs6>
            <DateField
              :date="abogado.fechaNacimiento"
              propiedad="fechaNacimiento"
              :birthDay="true"
              label="Fecha Nacimiento"
              :edit="false"
              @cambiarProp="cambiarProp"
            />
          </v-flex> -->
          <v-col cols="12" md="3" xs="6" class="px-1">
            <v-text-field
              label="Nombre(s)"
              class="required"
              :disabled="enviado"
              @keydown.enter="filtrar()"
              v-model="abogado.nombres"
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" xs="6" class="px-1">
            <v-text-field
              label="Primer Apellido"
              class="required"
              :disabled="enviado"
              @keydown.enter="filtrar()"
              v-model="abogado.primerApellido"
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" xs="6" class="px-1">
            <v-text-field
              label="Segundo Apellido"
              class="required"
              :disabled="enviado"
              @keydown.enter="filtrar()"
              v-model="abogado.segundoApellido"
              filled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="12"
            xs="12"
            v-if="totalDesserts>8"
          >
            <v-alert type="warning" color="orange" :value="true" outlined>
                Cantidad de Coincidencias Encontradas : {{totalDesserts}}, Ingrese más opciones para una mejor busqueda  
            </v-alert>
          </v-col>
          <v-col cols="12" xs="12">
            <v-data-table
              class="elevation-0"
              :headers="headers"
              :items="abogados"
              :options="pagination"
              :server-items-length="totalDesserts"
              :loading="loading"
              no-data-text="Sin Resultados"
              hide-default-footer
            >
              <template v-slot:body="{items}">
                <tbody>
                <tr v-for="(item,index) in items" :key="index" @dblclick="selecionarAbogado(item)" class="busqueda">
                  <td class="text-xs-left">{{item.matricula}}</td>
                  <td class="text-xs-center"  v-touch="{right: () => selecionarAbogado(item)}">
                    <v-avatar
                      :size="45"
                      color="grey lighten-4"
                      v-if="item.fotoAbogado!=null"
                    >
                      <img :src="doc_url+item.fotoAbogado" alt="avatar" />
                    </v-avatar>
                  </td>
                  <td
                    class="text-xs-left"
                    v-touch="{right: () => selecionarAbogado(item)}"
                  >{{item.nombreCompleto}}</td>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click="sinRegistro()"
          v-if="consulta && totalDesserts==0"
        >ABOGADO NO REGISTRADO</v-btn>
        <v-btn color="red" text @click="dialog = false">Cancelar y Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import DateField from "@/common/util/crud/DateField";
export default {
  components: {
    // DateField
  },
  data() {
    return {
      dialog: false,
      titulo: "Busqueda de Abogado Registrados",
      enviado: false,
      modal: false,
      loading: false,
      consulta: false,
      abogados: [],
      totalDesserts: 0,
      pagination: {},
      doc_url: this.$docUrl,
      headers: [
        {
          text: "Matricula",
          align: "left",
          sortable: false
        },
        {
          text: "Foto",
          align: "center",
          sortable: false
        },
        {
          text: "Nombre Abogado",
          align: "left",
          sortable: false
        }
      ],
      abogado: {
        id: null,
        nombres: null,
        primerApellido: null,
        segundoApellido: null,
        numeroDocumento: null,
        complemento: null,
        fechaNacimiento: null
      }
    };
  },
  methods: {
    listaAbogados(parameters) {
      this.desserts = [];
      this.consulta = false;
      this.loading = true;
      var config = {
        params: parameters
      };
      this.desserts = [];
      this.loading = true;
      this.enviado = true;
      this.$http
        .get(`${this.$apiUrl}buscar`, config)
        .then(response => {
          this.consulta = true;
          this.loading = false;
          this.enviado = false;
          this.abogados = response.data.data.items;
          this.totalDesserts = response.data.data.total_count;
        })
        .catch(error => {
          this.loading = false;
          this.enviado = false;
          this.desserts = [];
          this.totalDesserts = 0;
        });
    },
    filtrar() {
      this.pagination.rowsPerPage = 10;
      this.pagination.page = 1;
      const params = {
        // limit: this.pagination.rowsPerPage,
        // page: this.pagination.page,
        numeroDocumento: this.abogado.numeroDocumento,
        nombres: this.abogado.nombres,
        primerApellido: this.abogado.primerApellido,
        segundoApellido: this.abogado.segundoApellido
      };
      this.getApi(params);
    },
    getApi(parameters) {
      this.listaAbogados(parameters);
    },
    sinRegistro() {
      this.dialog = false;
      this.$emit("seleccion", this.abogado);
    },
    selecionarAbogado(item) {
      if (item.hasOwnProperty("estadoAbogado")) {
        if (item.estadoAbogado == "INHABILITADO") {
          return this.$notify({
            group: "foo",
            type: "error",
            title: "Error en Certificacion",
            text: "No Puede Seleccionar un Registro INHABILITADO"
          });
        }
      }
      this.dialog = false;
      this.reset()
      this.$emit("seleccion", item);
    },
    reset(){
        this.abogado = {
            id: null,
            nombres: null,
            primerApellido: null,
            segundoApellido: null,
            numeroDocumento: null,
            complemento: null,
            fechaNacimiento: null
        }
        this.abogados = [];
        this.totalDesserts = 0
    }
  }
};
</script>
<style>
tr.busqueda:hover {
  background: #cacef3;
  outline: none;
  cursor: pointer;
}
</style>
